import React from 'react'

import { Layout, Navbar } from '../Layout'
import { Contact } from '../components/Contact'

const ContactPage = () => (
	<Layout
		title="Contact"
		navbar={<Navbar position="absolute"/>}
		content={<Contact/>}
		footer={null}
	/>
)

export default ContactPage