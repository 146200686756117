import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import styled from 'styled-components'
import { Pane } from 'evergreen-ui'
import { useStaticQuery, graphql } from 'gatsby' // to query for image data

import { Typography, TextInput, Button, Toast } from '../components/primitives'
import { LOG_EMAIL } from '../../utils/graphql/mutations'

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 4em 2em;
  line-break: loose;

  a {
    color: ${({theme}) => theme.text };
  }
`
const SubscribeButton = styled(Button)`
	background-image: 
	${({theme}) => theme.name === 'light' 
		? 'linear-gradient(to bottom, #000, #000)' 
		: 'linear-gradient(to bottom, #FFF, #FFF)'} !important;
	padding: .75rem 1rem;
	height: 54px;
	margin: 0 1rem;
`


export const Contact = () => {

	const [email, setEmail] = useState('')

	const [logEmail, { loading }] = useMutation(
		LOG_EMAIL, {
			onCompleted: () => {
				Toast.success({
					message: 'You\'re subscribed!'
				})
			},
			onError: (err) => {
				console.warn(err)
			}
		}
	)
  
	const doLogEmail = () => {
		if(email) {
			logEmail(
				{
					variables: {
						email
					}
				}
			)
		}
	}
  
	const {twitterIcon, instagramIcon} = useStaticQuery(graphql`
		query {
      twitterIcon: file(name: {eq: "twitter"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      instagramIcon: file(name: {eq: "instagram"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      facebookIcon: file(name: {eq: "facebook"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
		}
	`)
  
	return (
		<Wrapper className="w-full md:w-2/3">
			<Typography variant="h3" weight="black">Contact Us</Typography>
			<Typography variant="h6" weight="light" className="mt-4 mb-10">Got questions? Shoot us a message.</Typography>
			<Typography variant="h4" weight="bold" className="mt-4">Email us at</Typography>
			<Typography variant="h6" weight="light">
				<a href="mailto:utterfungames@gmail.com">utterfungames@gmail.com</a>
			</Typography>

			<Typography variant="h4" weight="bold" className="mt-8">Say Hi on social media</Typography>
			<Pane display="flex" marginY="1rem">
				<a href="https://twitter.com/utterfungames"><img src={twitterIcon.childImageSharp.fluid.src} width="32" className="mr-4"/></a>
				<a href="https://www.instagram.com/utterfungames/"><img src={instagramIcon.childImageSharp.fluid.src} width="32" className="mr-4"/></a>
				{/* <a href=""><img src={facebookIcon.childImageSharp.fluid.src} width="32" className="mr-4"/></a> */}
			</Pane>

			<div className="flex flex-col my-20 w-full md:w-2/3">
				<Typography variant="h4" weight="bold" className="mt-4">
							Subscribe
				</Typography>
				<Typography variant="body" weight="normal" className="mb-4 self-start text-left">
							Get the latest news about Utter Fun Games!
				</Typography>

				<div className="flex items-center">
					<TextInput 
						type="email" 
						name="email" 
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className="flex-1"
						placeholder="Enter your email address" />
					<SubscribeButton
						onClick={() => doLogEmail()}
						loading={loading}
					>
						<Typography variant="body" weight="black" inverse={true} className="text-center m-0">Subscribe</Typography>
					</SubscribeButton>
				</div>
			</div>
		</Wrapper>
	)
}